import React from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  getDefaultDataExportSchedule,
  useDataExportSchedules
} from '../../../../services/schedules/exports';
import { styled } from '../../../../emotion';
import { Form } from '../../../../components/Form';
import { FormField } from '../../../../components/Form/FormField';
import { FrequencyEdit } from '../../../../components/Schedule/FrequencyEdit';
import { Loader } from '../../../../components/Loader';
import { DataExportSchedule } from '../../../../domainTypes/schedule';
import { useDialogState } from '../../../../hooks/useDialogState';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import shortid from 'shortid';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import { UserAvatarById } from '../../../../components/UserAvatars';
import { capitalize, compact } from 'lodash';
import { InternalId } from '../../../../components/ConnectionId';
import { Date } from '../../../../components/Dates';
import { FlexContainer } from '../../../../layout/Flex';
import { ISpace } from '../../../../domainTypes/space';
import { AdditionalActionsMenu } from '../../../../components/AdditionalActionsMenu';
import { removeDoc } from '../../../../services/db';
import { Check, Info, X } from 'react-feather';
import { Centered } from '../../../../layout/Centered';
import { WithShape } from '../../../../components/Charts/Util';
import { COLORS } from '../../../../domainTypes/colors';
import { Dash } from '../../../../components/Table/CountCell';
import { toMoment } from '../../../../services/time';
import { useMixpanel } from '../../../../services/mixpanel';

const Row = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ExportSchedule = ({
  schedule,
  onSubmit,
  onSuccess,
  onCancel
}: {
  schedule: DataExportSchedule;
  onSubmit: (next: DataExportSchedule) => Promise<void>;
  onSuccess: () => void;
  onCancel?: () => void;
}) => {
  const validate = (data: DataExportSchedule) => {
    const errors: Record<string, any> = {};
    if (!data.config.bucket) {
      errors.config = { bucket: 'You must provide a bucket name' };
    }

    if (
      data.config.path &&
      (data.config.path.startsWith('/') || data.config.path.endsWith('/'))
    ) {
      errors.config = {
        ...errors.config,
        path: 'Path must not start or end with a slash. Leave blank for root.'
      };
    }

    return errors;
  };

  return (
    <Form
      interceptor={true}
      validate={validate}
      onSubmit={(data) => {
        try {
          onSubmit(data as DataExportSchedule);
          onSuccess();
        } catch (e) {
          console.error(e);
        }
      }}
      initialValues={schedule}
    >
      {({ submitting, invalid }) => (
        <>
          <DialogContent>
            <Row>
              <InputLabel>Export type</InputLabel>
            </Row>
            <Row>
              <FormField name="config.exportType" type="input">
                {({ input }) => (
                  <Select
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="CLICKS">Clicks</MenuItem>
                    <MenuItem value="TRANSACTIONS">Transactions</MenuItem>
                    <MenuItem value="CUSTOM" disabled>
                      Custom query
                    </MenuItem>
                  </Select>
                )}
              </FormField>
            </Row>
            <br />
            <Row>
              <InputLabel>Destination type</InputLabel>
            </Row>
            <Row>
              <FormField name="config.destination" type="input">
                {({ input }) => (
                  <Select
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                    disabled
                    variant="outlined"
                  >
                    <MenuItem value="HOSTED_GCS_BUCKET">
                      <WithShape color={COLORS.blue.blue6} shape="square">
                        Affilimate-hosted GCS bucket
                      </WithShape>
                    </MenuItem>
                    <MenuItem value="EXTERNAL_GCS_BUCKET" disabled>
                      <WithShape color={COLORS.blue.blue3} shape="square">
                        External GCS bucket
                      </WithShape>
                    </MenuItem>
                    <MenuItem value="S3" disabled>
                      <WithShape color={COLORS.volcano.volcano6} shape="square">
                        Amazon S3 bucket
                      </WithShape>
                    </MenuItem>
                  </Select>
                )}
              </FormField>
            </Row>
            <br />
            <Row>
              <InputLabel>Destination</InputLabel>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: '4px' }}
              >
                Provide the bucket name you were assigned by Affilimate.
              </Typography>
            </Row>
            <Row>
              <FormField name="config.bucket" type="text">
                {({ input, meta }) => (
                  <>
                    <TextField
                      label="Bucket name"
                      placeholder="media-co-affilimate"
                      value={input.value}
                      onChange={input.onChange}
                      disabled={submitting}
                      error={meta.error && meta.dirty}
                      helperText={meta.dirty && meta.error}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField name="config.path" type="text">
                {({ input, meta }) => (
                  <TextField
                    label="Folder path (Optional)"
                    placeholder="clicks"
                    value={input.value}
                    onChange={input.onChange}
                    disabled={submitting}
                    error={meta.error && meta.dirty}
                    helperText={meta.dirty && meta.error}
                    variant="outlined"
                    fullWidth
                  />
                )}
              </FormField>
            </Row>
            <br />
            <Row>
              <InputLabel>Export scheduling</InputLabel>
            </Row>
            <Row>
              <FormField name="frequency">
                {({ input }) => (
                  <FrequencyEdit
                    value={input.value}
                    onChange={input.onChange as any}
                  />
                )}
              </FormField>
            </Row>
            <br />
            <Row>
              <FormField name="active" type="switch">
                {({ input }) => (
                  <FormControlLabel
                    label={`Enable this export schedule`}
                    control={
                      <Switch
                        color="primary"
                        checked={input.value}
                        onChange={input.onChange}
                      />
                    }
                  />
                )}
              </FormField>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || invalid}
            >
              {submitting ? 'Saving schedule...' : 'Save schedule'}
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
};

const CreateOrEditScheduleDialog = ({
  schedule,
  onSaveSchedule,
  onClose
}: {
  schedule: DataExportSchedule;
  onSaveSchedule: (schedule: DataExportSchedule) => Promise<void>;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () => {
    enqueueSnackbar('Export schedule saved successfully', {
      variant: 'success'
    });
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} scroll="body" fullWidth maxWidth="sm">
      <DialogTitle>Define your scheduled export</DialogTitle>
      <ExportSchedule
        schedule={schedule}
        onSubmit={onSaveSchedule}
        onSuccess={onSuccess}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export const AddScheduledExportButton = ({
  ...buttonProps
}: Omit<ButtonProps, 'onClick'>) => {
  const currentUser = useCurrentUser();
  const mp = useMixpanel();
  const { dialogOpen, closeDialog, openDialog } = useDialogState();
  const newSchedule = getDefaultDataExportSchedule(
    currentUser.space.id,
    currentUser.id,
    currentUser.space.config.tz
  );

  const createSchedule = async (data: DataExportSchedule) => {
    return updateOrCreateSchedule(shortid(), data).then(() => {
      mp.track('scheduled_exports_create');
    });
  };

  return (
    <>
      <Button onClick={openDialog} {...buttonProps} />
      {dialogOpen && (
        <CreateOrEditScheduleDialog
          schedule={newSchedule}
          onSaveSchedule={createSchedule}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

const MIN_HEIGHT = 150;

export const ScheduledExports = ({ space }: { space: ISpace }) => {
  const spaceId = space.id;
  const scopes = useCurrentUserScopes();
  const mp = useMixpanel();
  const [schedules] = useDataExportSchedules(spaceId);

  const canEditScheduledExports = scopes.has('scheduled_exports.edit');
  const canDeleteScheduledExports = scopes.has('scheduled_exports.delete');

  // TODO: Add permissions

  return (
    <div>
      {!schedules ? (
        <Loader height={MIN_HEIGHT} />
      ) : schedules.length === 0 ? (
        <Centered height={MIN_HEIGHT}>
          <Typography variant="body2" color="textSecondary">
            No scheduled exports found. Click the button to{' '}
            <strong>Add scheduled export</strong> to get started.
          </Typography>
        </Centered>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Enabled</TableCell>
              <TableCell>Export</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Export ID</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((doc) => (
              <TableRow key={doc.id}>
                <TableCell>
                  <FlexContainer alignItems="center" justifyContent="center">
                    {doc.data.active ? <Check size={18} /> : <X size={18} />}
                  </FlexContainer>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {capitalize(doc.data.config.exportType)}{' '}
                    {doc.data.frequency.type === 'NONE'
                      ? 'No schedule'
                      : doc.data.frequency.type === 'DAILY'
                      ? `daily at ${doc.data.frequency.hour}:00`
                      : `weekly on ${doc.data.frequency.weekDay} at ${doc.data.frequency.hour}:00`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={
                      doc.data.lastRun?.status === 'ERROR'
                        ? 'error'
                        : 'textSecondary'
                    }
                  >
                    {doc.data.lastRun ? (
                      <>
                        Last run with status {doc.data.lastRun.status}{' '}
                        <Tooltip
                          placement="top"
                          title={`Last run occurred at ${toMoment(
                            doc.data.lastRun.date
                          ).format('LLL')} with status ${
                            doc.data.lastRun.status
                          }.`}
                        >
                          <Info
                            size={12}
                            style={{ position: 'relative', top: '1px' }}
                          />
                        </Tooltip>
                      </>
                    ) : doc.data.nextRun && doc.data.active ? (
                      <>
                        First run scheduled{' '}
                        <Tooltip
                          placement="top"
                          title={`First run scheduled for ${toMoment(
                            doc.data.nextRun
                          ).format(
                            'LLL'
                          )}, and may take up to 30 minutes to complete.`}
                        >
                          <Info
                            size={12}
                            style={{ position: 'relative', top: '1px' }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      'No runs yet'
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    <WithShape color={COLORS.blue.blue6} shape="square">
                      {doc.data.config.bucket} / {doc.data.config.path}
                    </WithShape>
                  </Typography>
                </TableCell>
                <TableCell>
                  <InternalId>{doc.id}</InternalId>
                </TableCell>
                <TableCell>
                  <FlexContainer alignItems="center" spacing={1}>
                    <UserAvatarById
                      userId={doc.data.createdBy}
                      spaceId={spaceId}
                    />
                    <div>on</div>
                    <Date d={doc.data.createdAt.toMillis()} />
                  </FlexContainer>
                </TableCell>
                <TableCell>
                  {canEditScheduledExports || canDeleteScheduledExports ? (
                    <AdditionalActionsMenu
                      label="Schedule actions"
                      options={compact([
                        canEditScheduledExports && {
                          key: 'edit',
                          label: 'Edit schedule',
                          dialogMaxWidth: 'sm',
                          dialog: ({ onClose }) => (
                            <CreateOrEditScheduleDialog
                              schedule={doc.data}
                              onSaveSchedule={(data) =>
                                updateOrCreateSchedule(doc.id, data).then(
                                  () => {
                                    mp.track('scheduled_exports_edit');
                                  }
                                )
                              }
                              onClose={onClose}
                            />
                          )
                        },
                        canDeleteScheduledExports && {
                          key: 'delete',
                          label: 'Delete schedule',
                          dialogMaxWidth: 'sm',
                          dialog: ({ onClose }) => (
                            <>
                              <DialogTitle>Delete schedule?</DialogTitle>
                              <DialogContent>
                                <Typography variant="body1" component="p">
                                  Once you delete this schedule, any future
                                  exports will not run.
                                </Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={onClose}>Cancel</Button>

                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    removeDoc(doc).then(() => {
                                      mp.track('scheduled_exports_delete');
                                      onClose();
                                    });
                                  }}
                                >
                                  Delete schedule
                                </Button>
                              </DialogActions>
                            </>
                          )
                        }
                      ])}
                    />
                  ) : (
                    <Dash />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

import { FilterDefinition, FiltersDefinition, printFilters } from '../filters';
import { listToQueryParams } from './query-params';
import { isEqual } from 'lodash';

const mutableWithoutUndefined = <T>(obj: {
  [key: string]: T | undefined;
}): { [key: string]: T } => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return (obj as unknown) as { [key: string]: T };
};

export const interpolatePath = (
  pathTemplate: string,
  params: { [key: string]: string },
  splat?: string
) => {
  const url = Object.keys(params).reduce(
    (path, key) => path.replace(':' + key, params[key]),
    pathTemplate
  );
  return splat ? url.replace(/\*$/, splat) : url;
};

export type Pathnames = {
  staff: {
    overview: string;
  };
  dashboard: {
    overview: string;
    overviewV2: string;
    realtime: string;
  };
  performanceNew: {
    transactions: string;
    networks: {
      overview: string;
      details: {
        overview: string;
      };
    };
    channels: string;
    advertisers: {
      overview: string;
      details: string;
    };
    rates: {
      overview: string;
    };
    payouts: {
      overview: string;
      details: {
        overview: string;
      };
    };
    products: string;
    integrations: string;
    rules: string;
    rulesV2: string;
    activity: string;
  };
  content: {
    overview: string;
    overview_v2: string;
    tags: {
      manage: string;
      authors: string;
      group: string;
    };
    details: {
      trends: string;
      heatmap: string;
      links: string;
      revisions: string;
      audience: string;
    };
    referrers: {
      overview: string;
      groupDetails: string;
    };
    utm: {
      details: string;
    };
    customDimensions: {
      slot: string;
      manage: string;
      details: string;
    };
  };
  revisions: {
    overview: string;
  };
  links: {
    overview: string;
    overview_v2: string;
    export: string;
    amazonIssues: string;
    amazonSettings: string;
    scans: {
      history: string;
      details: string;
    };
    settings: string;
    details: {
      overview: string;
      pages: string;
      audience: string;
    };
  };
  campaigns: {
    overview: string;
    complete: string;
    details: string;
    create: string;
    report: string;
    performance: string;
    settings: string;
  };
  audit: {
    overview: string;
    details: string;
  };
  tools: {
    linkGenerator: string;
  };
  settings: {
    general: string;
    tracking: string;
    content: string;
    notifications: string;
    profile: string;
    billing: string;
    browserExtension: string;
    affiliateProgram: string;
    users: string;
    api: string;
    exports: string;
  };
  analytics: {
    content: string;
    audience: string;
    traffic: string;
  };
  products: {
    overview: string;
    details: string;
  };
  productScans: {
    overview: string;
    details: string;
  };
  pages: {
    overview: string;
    details: string;
    audience: string;
    heatmap: string;
    revisions: string;
  };
  partners: {
    overview: string;
    details: string;
  };
  recommendations: {
    overview: string;
  };
  schedules: {
    home: string;
  };
  docs: {
    knowledgeBase: string;
    api: string;
  };
  performance: {
    overview: string;
    settings: string;
    reports: string;
    integrations: string;
    labelRules: string;
  };
  invite: {
    accept: string;
  };
};

export const PATHS: Pathnames = {
  staff: {
    overview: '/staff'
  },
  dashboard: {
    overview: '/dashboard',
    overviewV2: '/dashboard_v2',
    realtime: '/realtime'
  },
  recommendations: {
    overview: '/recommendations'
  },
  content: {
    overview_v2: '/content',
    overview: '/content/legacy',
    tags: {
      manage: '/content/tags/manage',
      authors: '/content/tags/authors',
      group: '/content/tags/group/:groupId'
    },
    customDimensions: {
      manage: '/content/custom-dimensions/manage',
      slot: '/content/custom-dimensions/slot/:slot',
      details: '/content/custom-dimensions/slot/:slot/details/:value'
    },
    details: {
      trends: '/content/trends',
      heatmap: '/content/heatmap',
      links: '/content/links',
      revisions: '/content/revisions',
      audience: '/content/audience'
    },
    referrers: {
      overview: '/content/referrers',
      groupDetails: '/content/referrers/group/:groupId'
    },
    utm: {
      details: '/content/utm/:parameter'
    }
  },
  performanceNew: {
    advertisers: {
      overview: '/performance-new/advertisers/',
      details: '/performance-new/advertisers/details'
    },
    rates: {
      overview: '/performance-new/rates/'
    },
    payouts: {
      overview: '/performance-new/payouts/',
      details: {
        overview: '/performance-new/payouts/:id'
      }
    },
    transactions: '/performance-new/transactions',
    networks: {
      overview: '/performance-new/networks',
      details: {
        overview: '/performance-new/networks/:partnerKey'
      }
    },
    channels: '/performance-new/channels',
    products: '/performance-new/products',
    integrations: '/performance-new/integrations',
    rules: '/performance-new/rules-old',
    rulesV2: '/performance-new/rules',
    activity: '/performance-new/activity'
  },
  revisions: {
    overview: '/revisions'
  },
  links: {
    overview: '/links/legacy',
    overview_v2: '/links',
    export: '/links/export',
    amazonIssues: '/links/amazon-issues',
    scans: {
      history: '/scans',
      details: '/scans/:scanId'
    },
    settings: '/links/settings',
    amazonSettings: '/links/amazon-settings',
    details: {
      overview: '/links/:productId/overview',
      pages: '/links/:productId/pages',
      audience: '/links/:productId/audience'
    }
  },
  campaigns: {
    overview: '/campaigns',
    complete: '/campaigns/complete',
    settings: '/campaigns/settings',
    performance: '/campaigns/performance',
    details: '/campaigns/:campaignId/details',
    report: '/campaigns/:campaignId/report',
    create: '/campaigns/new'
  },
  tools: {
    linkGenerator: '/tools/link-generator'
  },
  audit: {
    overview: '/audit',
    details: '/audit/:siteUrl'
  },
  settings: {
    general: '/settings/general',
    tracking: '/settings/tracking',
    content: '/settings/content',
    notifications: '/settings/notifications',
    profile: '/settings/profile',
    billing: '/settings/billing',
    browserExtension: '/settings/browser-extension',
    affiliateProgram: '/settings/affiliate',
    users: '/settings/users',
    api: '/settings/api',
    exports: '/settings/exports'
  },

  analytics: {
    content: '/analytics/content',
    audience: '/analytics/audience',
    traffic: '/analytics/traffic'
  },
  performance: {
    overview: '/performance/home',
    settings: '/performance/settings',
    reports: '/performance/reports',
    integrations: '/performance/integrations',
    labelRules: '/performance/label-rules'
  },
  products: {
    overview: '/products/all',
    details: '/products/detail/:productId'
  },
  productScans: {
    overview: '/scan/home',
    details: '/scan/detail/:scanId'
  },
  pages: {
    overview: '/pages/all',
    details: '/pages/detail',
    audience: '/pages/audience',
    heatmap: '/pages/heatmap',
    revisions: '/pages/revisions'
  },
  schedules: {
    home: '/schedules'
  },
  docs: {
    knowledgeBase: '/docs/:slug',
    api: '/api'
  },
  partners: {
    overview: '/partners/home',
    details: '/partners/detail/:partnerKey'
  },
  invite: {
    accept: '/accept-invitation/:inviteId'
  }
};

export type Routes = {
  staff: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  dashboard: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    overviewV2: {
      url: (utmParams?: UtmParams) => string;
    };
    realtime: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  invite: {
    accept: {
      url: (inviteId: string, utmParams?: UtmParams) => string;
    };
  };
  recommendations: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  content: {
    overview: {
      url: (
        filters?: { site?: string; tags?: string },
        utmParams?: UtmParams
      ) => string;
    };
    overview_v2: {
      url: (
        filters?: FiltersDefinition,
        timeframe?: {
          start: string;
          end: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    tags: {
      group: {
        url: (groupId: string, utmParams?: UtmParams) => string;
      };
      manage: {
        url: (utmParams?: UtmParams) => string;
      };
      authors: {
        url: (utmParams?: UtmParams) => string;
      };
    };
    customDimensions: {
      slot: {
        url: (slot: string, utmParams?: UtmParams) => string;
      };
      manage: {
        url: (utmParams?: UtmParams) => string;
      };
      details: {
        url: (slot: string, value: string, utmParams?: UtmParams) => string;
      };
    };
    details: {
      trends: {
        url: (url: string, utmParams?: UtmParams) => string;
      };
      heatmap: {
        url: (url: string, utmParams?: UtmParams) => string;
      };
      links: {
        url: (url: string, utmParams?: UtmParams) => string;
      };
      revisions: {
        url: (url: string, utmParams?: UtmParams) => string;
      };
      audience: {
        url: (url: string, utmParams?: UtmParams) => string;
      };
    };
    referrers: {
      overview: {
        url: (
          filters?: { timeframe?: { start: string; end: string } },
          utmParams?: UtmParams
        ) => string;
      };
      groupDetails: {
        url: (groupId: string, utmParams?: UtmParams) => string;
      };
    };
    utm: {
      details: {
        url: (
          parameterType: string,
          filters?: {
            timeframe?: { start: string; end: string };
          },
          utmParams?: UtmParams
        ) => string;
      };
    };
  };
  performanceNew: {
    transactions: {
      url: (
        params?: {
          filters?: FiltersDefinition;
          q?: string;
          timeframe?: {
            start: string;
            end: string;
          };
        },
        utmParams?: UtmParams
      ) => string;
    };
    networks: {
      overview: {
        url: (
          filters?: {
            filters?: FilterDefinition[];
          },
          utmParams?: UtmParams
        ) => string;
      };
      details: {
        overview: {
          url: (partnerKey: string, utmParams?: UtmParams) => string;
        };
      };
    };
    advertisers: {
      overview: {
        url: (
          filters?: {
            filters?: FilterDefinition[];
            q?: string;
          },
          utmParams?: UtmParams
        ) => string;
      };
      details: {
        url: (
          filters?: {
            site?: string;
            advertisers?: string;
            q?: string;
            partners?: string;
            timeframe?: string;
          },
          utmParams?: UtmParams
        ) => string;
      };
    };
    rates: {
      overview: {
        url: (utmParams?: UtmParams) => string;
      };
    };
    payouts: {
      overview: {
        url: (utmParams?: UtmParams) => string;
      };
      details: {
        overview: {
          url: (partnerKey: string, utmParams?: UtmParams) => string;
        };
      };
    };
    channels: {
      url: (
        filters?: { timeframe?: string; interval_unit?: string },
        utmParams?: UtmParams
      ) => string;
    };
    products: {
      url: (utmParams?: UtmParams) => string;
    };
    integrations: {
      url: (utmParams?: UtmParams) => string;
    };
    activity: {
      url: (utmParams?: UtmParams) => string;
    };
    rules: {
      url: (utmParams?: UtmParams) => string;
    };
    rulesV2: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  revisions: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  links: {
    overview: {
      url: (
        params?: {
          q?: string;
          issuesOnly?: string;
          partners?: string;
          sort?: string;
          'table-mode'?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    overview_v2: {
      url: (
        filters?: {
          timeframe?: { start: string; end: string };
        },
        link_id?: string,
        utmParams?: UtmParams
      ) => string;
    };
    export: {
      url: (utmParams?: UtmParams) => string;
    };
    amazonIssues: {
      url: (
        params?: {
          q?: string;
          sort?: string;
          'table-mode'?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    scans: {
      history: {
        url: (utmParams?: UtmParams) => string;
      };
      details: {
        url: (scanId: string, utmParams?: UtmParams) => string;
      };
    };
    settings: {
      url: (utmParams?: UtmParams) => string;
    };
    amazonSettings: {
      url: (utmParams?: UtmParams) => string;
    };
    details: {
      overview: {
        url: (
          productId: string,
          back?: 'overview' | 'amazonIssues',
          utmParams?: UtmParams
        ) => string;
      };
      pages: {
        url: (
          productId: string,
          back?: 'overview' | 'amazonIssues',
          utmParams?: UtmParams
        ) => string;
      };
      audience: {
        url: (
          productId: string,
          back?: 'overview' | 'amazonIssues',
          utmParams?: UtmParams
        ) => string;
      };
    };
  };
  campaigns: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    complete: {
      url: (utmParams?: UtmParams) => string;
    };
    details: {
      url: (
        campaignId: string,
        back: 'overview' | 'report',
        utmParams?: UtmParams
      ) => string;
    };
    report: {
      url: (campaignId: string, utmParams?: UtmParams) => string;
    };
    create: {
      url: (utmParams?: UtmParams) => string;
    };
    performance: {
      url: (utmParams?: UtmParams) => string;
    };
    settings: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  tools: {
    linkGenerator: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  docs: {
    knowledgeBase: {
      url: (scanId?: string, utmParams?: UtmParams) => string;
    };
    api: {
      url: (scanId?: string, utmParams?: UtmParams) => string;
    };
  };
  audit: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    details: {
      url: (siteUrl: string, utmParams?: UtmParams) => string;
    };
  };
  settings: {
    general: {
      url: (utmParams?: UtmParams) => string;
    };
    tracking: {
      url: (utmParams?: UtmParams) => string;
    };
    content: {
      url: (utmParams?: UtmParams) => string;
    };
    notifications: {
      url: (utmParams?: UtmParams) => string;
    };
    profile: {
      url: (utmParams?: UtmParams) => string;
    };
    billing: {
      url: (utmParams?: UtmParams) => string;
    };
    api: {
      url: (utmParams?: UtmParams) => string;
    };
    exports: {
      url: (utmParams?: UtmParams) => string;
    };
    browserExtension: {
      url: (
        { install }: { install?: boolean },
        utmParams?: UtmParams
      ) => string;
    };
    affiliateProgram: {
      url: (utmParams?: UtmParams) => string;
    };
    users: {
      url: (utmParams?: UtmParams) => string;
    };
  };

  base: {
    url: (opts?: { checkout?: boolean }, utmParams?: UtmParams) => string;
  };
  partners: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    details: {
      url: (partnerKey: string, utmParams?: UtmParams) => string;
    };
  };
  performance: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    settings: {
      url: (utmParams?: UtmParams) => string;
    };
    reports: {
      url: (utmParams?: UtmParams) => string;
    };
    integrations: {
      url: (utmParams?: UtmParams) => string;
    };
    labelRules: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  analytics: {
    content: {
      url: (utmParams?: UtmParams) => string;
    };
    audience: {
      url: (utmParams?: UtmParams) => string;
    };
    traffic: {
      url: (utmParams?: UtmParams) => string;
    };
  };
  products: {
    overview: {
      url: (
        params?: {
          search?: string;
          issuesOnly?: string;
          partners?: string;
          sort?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    details: {
      url: (productId: string, utmParams?: UtmParams) => string;
    };
  };
  productScans: {
    overview: {
      url: (utmParams?: UtmParams) => string;
    };
    details: {
      url: (scanId: string, utmParams?: UtmParams) => string;
    };
  };
  pages: {
    overview: {
      url: (search?: string, utmParams?: UtmParams) => string;
    };
    audience: {
      url: (
        url: string,

        queryParams?: {
          revision?: string;
          timeframe?: string;
          syncTimeframe?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    details: {
      url: (
        url: string,

        queryParams?: {
          revision?: string;
          timeframe?: string;
          syncTimeframe?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    heatmap: {
      url: (
        url: string,
        queryParams?: {
          revision?: string;
          timeframe?: string;
          syncTimeframe?: string;
        },
        utmParams?: UtmParams
      ) => string;
    };
    revisions: {
      url: (url: string, utmParams?: UtmParams) => string;
    };
  };
  schedules: {
    home: {
      url: (utmParams?: UtmParams) => string;
    };
  };
};

export type UtmParams = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
};

export type Query = {
  [key: string]: string | string[] | null | undefined;
};

const withoutEmpty = (query: Query) =>
  Object.keys(query).reduce((m, k) => {
    const v = query[k];
    if (v && v.length) {
      m[k] = v;
    }
    return m;
  }, {} as Query);

const withQuery = (
  url: string,
  params: Query,
  stringify: (o: { [key: string]: any }) => string
) => {
  const queryParams = withoutEmpty(params);
  return `${url}${
    Object.keys(queryParams).length ? `?${stringify(queryParams)}` : ''
  }`;
};

const toUtm = ({ medium, source, campaign, content, term }: UtmParams) => {
  const result: { [key: string]: string } = {};
  medium && (result['utm_medium'] = medium);
  source && (result['utm_source'] = source);
  campaign && (result['utm_campaign'] = campaign);
  content && (result['utm_content'] = content);
  term && (result['utm_term'] = term);
  return result;
};

const defaultTransactionTypeFilter: FilterDefinition = {
  k: 'transaction_type',
  v: ['cpc', 'cpl', 'cpa', 'unknown']
};

const withDefaultFilters = (
  filters: FilterDefinition[],
  defaults: FilterDefinition[]
) => {
  return [
    ...filters,
    ...defaults.filter((d) => !filters.find((f) => f.k === d.k))
  ];
};

const backCompatFilters = (filters: FilterDefinition[]) => {
  return filters.reduce((acc, def) => {
    switch (def.k) {
      case 'site':
        return { ...acc, site: listToQueryParams(def.v) };
      case 'transaction_type': {
        return isEqual(def, defaultTransactionTypeFilter)
          ? acc
          : {
              ...acc,
              sale_status: listToQueryParams(def.v)
            };
      }
      case 'payout_id':
        return { ...acc, payout_ids: listToQueryParams(def.v) };
      case 'platform':
        return { ...acc, partners: listToQueryParams(def.v) };
      case 'advertiser':
        return { ...acc, advertisers: listToQueryParams(def.v, '---') };
      default:
        return acc;
    }
  }, {});
};

export const createRoutes = (
  toUrlFn: (
    path: string,
    query?: Query,
    persistedQueryParams?: string[]
  ) => { path: string; query?: Query },
  stringify: (o: { [key: string]: any }) => string
): Routes => {
  const toUrl = (
    path: string,
    query?: Query,
    persistedQueryParams?: string[]
  ) => {
    const d = toUrlFn(
      path,
      mutableWithoutUndefined(query || {}),
      persistedQueryParams
    );
    return withQuery(d.path, d.query || {}, stringify);
  };
  return {
    invite: {
      accept: {
        url: (inviteId: string, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.invite.accept, {
              inviteId: encodeURIComponent(inviteId)
            }),
            toUtm(utmParams)
          )
      }
    },
    staff: {
      overview: {
        url: (utmParams = {}) => toUrl(PATHS.staff.overview, toUtm(utmParams))
      }
    },
    dashboard: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.dashboard.overview, toUtm(utmParams), ['timeframe'])
      },
      overviewV2: {
        url: (utmParams = {}) =>
          toUrl(PATHS.dashboard.overviewV2, toUtm(utmParams), ['timeframe'])
      },
      realtime: {
        url: (utmParams = {}) =>
          toUrl(PATHS.dashboard.realtime, toUtm(utmParams))
      }
    },
    recommendations: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.recommendations.overview, toUtm(utmParams))
      }
    },
    content: {
      overview: {
        url: (filters = {}, utmParams = {}) =>
          toUrl(
            PATHS.content.overview,
            {
              ...toUtm(utmParams),
              site: filters.site,
              tags: filters.tags
            },
            ['timeframe', 'site', 'table-mode']
          )
      },
      overview_v2: {
        url: (filters = [], timeframe = undefined, utmParams = {}) =>
          toUrl(
            PATHS.content.overview_v2,
            {
              ...toUtm(utmParams),
              timeframe: timeframe
                ? `${timeframe.start}---${timeframe.end}`
                : undefined,
              filter: printFilters(filters)
            },
            ['timeframe', 'site']
          )
      },
      tags: {
        group: {
          url: (groupId: string, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.content.tags.group, {
                groupId: encodeURIComponent(groupId)
              }),
              toUtm(utmParams),
              ['timeframe', 'site']
            )
        },
        authors: {
          url: (utmParams = {}) =>
            toUrl(PATHS.content.tags.authors, toUtm(utmParams), [
              'timeframe',
              'site'
            ])
        },
        manage: {
          url: (utmParams = {}) =>
            toUrl(PATHS.content.tags.manage, toUtm(utmParams))
        }
      },
      customDimensions: {
        slot: {
          url: (slot: string, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.content.customDimensions.slot, {
                slot: encodeURIComponent(slot)
              }),
              toUtm(utmParams),
              ['timeframe', 'site']
            )
        },
        details: {
          url: (slot: string, value: string, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.content.customDimensions.details, {
                slot: encodeURIComponent(slot),
                value: encodeURIComponent(value)
              }),
              toUtm(utmParams),
              ['timeframe', 'site']
            )
        },
        manage: {
          url: (utmParams = {}) =>
            toUrl(PATHS.content.customDimensions.manage, toUtm(utmParams))
        }
      },
      referrers: {
        overview: {
          url: (filters = {}, utmParams = {}) =>
            toUrl(
              PATHS.content.referrers.overview,
              {
                ...toUtm(utmParams),
                timeframe: filters.timeframe
                  ? `${filters.timeframe.start}---${filters.timeframe.end}`
                  : undefined
              },
              ['timeframe', 'site', 'metric']
            )
        },
        groupDetails: {
          url: (groupId: string, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.content.referrers.groupDetails, {
                groupId: encodeURIComponent(groupId)
              }),
              toUtm(utmParams),
              ['timeframe', 'site', 'metric']
            )
        }
      },
      utm: {
        details: {
          url: (parameter: string, filters = {}, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.content.utm.details, {
                parameter: encodeURIComponent(parameter)
              }),
              {
                ...toUtm(utmParams),
                timeframe: filters.timeframe
                  ? `${filters.timeframe.start}---${filters.timeframe.end}`
                  : undefined
              },
              ['timeframe', 'site', 'utmcols']
            )
        }
      },
      details: {
        trends: {
          url: (url: string, utmParams = {}) =>
            toUrl(PATHS.content.details.trends, { url, ...toUtm(utmParams) }, [
              'timeframe'
            ])
        },
        heatmap: {
          url: (url: string, utmParams = {}) =>
            toUrl(
              PATHS.content.details.heatmap,
              {
                url,
                ...toUtm(utmParams)
              },
              ['timeframe']
            )
        },
        links: {
          url: (url: string, utmParams = {}) =>
            toUrl(
              PATHS.content.details.links,
              {
                url,
                ...toUtm(utmParams)
              },
              ['timeframe']
            )
        },
        revisions: {
          url: (url: string, utmParams = {}) =>
            toUrl(
              PATHS.content.details.revisions,
              {
                url,
                ...toUtm(utmParams)
              },
              ['timeframe']
            )
        },
        audience: {
          url: (url: string, utmParams = {}) =>
            toUrl(
              PATHS.content.details.audience,
              {
                url,
                ...toUtm(utmParams)
              },
              ['timeframe']
            )
        }
      }
    },
    performanceNew: {
      transactions: {
        url: ({ filters = [], q, timeframe } = {}, utmParams = {}) => {
          const withDefaults = withDefaultFilters(filters, [
            defaultTransactionTypeFilter
          ]);

          // PREVIOUS CODE WHICH TRANSLATED FROM OLD FILTERS TO NEW FILTERS

          //   const sitesToFilter =
          //     sites.size === allSites.size + 1 // account for unattributed
          //       ? []
          //       : [...sites].map((s) => (s === UNATTRIBUTED ? '' : s));
          //   return compact([
          //     // + 1 to account for unattributed
          //     allSites.size + 1 !== sites.size && {
          //       field: 'page_url_origin',
          //       condition: 'in',
          //       values: sitesToFilter
          //     },
          //     selectedStatuses.size !== SALE_STATUSES.length && {
          //       field: 'sale_status',
          //       condition: 'in',
          //       values: [...selectedStatuses].map((x) => x.toLowerCase())
          //     },
          //     selectedTypes.size !== SALE_TYPES.length && {
          //       field: 'sale_type',
          //       condition: 'in',
          //       values: [...selectedTypes]
          //     },
          //     selectedPartners?.size && {
          //       field: 'pk',
          //       condition: 'in',
          //       values: [...selectedPartners]
          //     },
          //     selectedAdvertisers?.size && {
          //       field: 'advertiser_name',
          //       condition: 'in',
          //       values: [...selectedAdvertisers]
          //     },
          //     selectedPayoutIds?.size && {
          //       field: 'payout_id',
          //       condition: 'in',
          //       values: [...selectedPayoutIds]
          //     },
          //     selectedPayoutStatuses &&
          //       selectedPayoutStatuses.size !== PAYOUT_STATUSES.length && {
          //         field: 'payout_status',
          //         condition: 'in',
          //         values: [...selectedPayoutStatuses].map((x) => x.toLowerCase())
          //       }
          //   ]);

          return toUrl(
            PATHS.performanceNew.transactions,
            {
              ...toUtm(utmParams),
              filter: printFilters(withDefaults),
              ...backCompatFilters(withDefaults),
              q,
              timeframe: timeframe
                ? `${timeframe.start}---${timeframe.end}`
                : undefined
            },
            ['timeframe', 'site', 'interval_unit']
          );
        }
      },
      networks: {
        overview: {
          url: ({ filters = [] } = {}, utmParams = {}) => {
            const withDefaults = withDefaultFilters(filters, [
              defaultTransactionTypeFilter
            ]);
            return toUrl(
              PATHS.performanceNew.networks.overview,
              {
                filter: printFilters(withDefaults),
                ...backCompatFilters(withDefaults),
                ...toUtm(utmParams)
              },
              ['timeframe', 'interval_unit']
            );
          }
        },
        details: {
          overview: {
            url: (partnerKey: string, utmParams = {}) =>
              toUrl(
                interpolatePath(
                  PATHS.performanceNew.networks.details.overview,
                  { partnerKey }
                ),
                toUtm(utmParams),
                ['timeframe', 'interval_unit']
              )
          }
        }
      },
      advertisers: {
        overview: {
          url: ({ filters = [], q } = {}, utmParams = {}) => {
            const withDefaults = withDefaultFilters(filters, [
              defaultTransactionTypeFilter
            ]);
            return toUrl(
              PATHS.performanceNew.advertisers.overview,
              {
                ...toUtm(utmParams),
                filter: printFilters(withDefaults),
                ...backCompatFilters(withDefaults),
                q: q
              },
              ['timeframe', 'site', 'interval_unit']
            );
          }
        },
        details: {
          url: (filters = {}, utmParams = {}) =>
            toUrl(
              PATHS.performanceNew.advertisers.details,
              {
                ...toUtm(utmParams),
                site: filters.site,
                advertisers: filters.advertisers,
                partners: filters.partners,
                timeframe: filters.timeframe,
                q: filters.q
              },
              ['timeframe', 'site', 'interval_unit']
            )
        }
      },
      rates: {
        overview: {
          url: (utmParams = {}) =>
            toUrl(PATHS.performanceNew.rates.overview, toUtm(utmParams), [
              'timeframe'
            ])
        }
      },
      payouts: {
        overview: {
          url: (utmParams = {}) =>
            toUrl(PATHS.performanceNew.payouts.overview, toUtm(utmParams), [
              'timeframe'
            ])
        },
        details: {
          overview: {
            url: (id: string, utmParams = {}) =>
              toUrl(
                interpolatePath(PATHS.performanceNew.payouts.details.overview, {
                  id
                }),
                toUtm(utmParams),
                ['timeframe']
              )
          }
        }
      },
      channels: {
        url: (filters = {}, utmParams = {}) =>
          toUrl(
            PATHS.performanceNew.channels,
            { ...filters, ...toUtm(utmParams) },
            ['timeframe', 'interval_unit']
          )
      },
      products: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performanceNew.products, toUtm(utmParams), ['timeframe'])
      },
      integrations: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performanceNew.integrations, toUtm(utmParams))
      },
      activity: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performanceNew.activity, toUtm(utmParams))
      },
      rules: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performanceNew.rules, toUtm(utmParams))
      },
      rulesV2: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performanceNew.rulesV2, toUtm(utmParams))
      }
    },
    revisions: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.revisions.overview, toUtm(utmParams), ['timeframe'])
      }
    },
    links: {
      export: {
        url: (utmParams = {}) =>
          toUrl(PATHS.links.export, toUtm(utmParams), ['timeframe'])
      },
      overview: {
        url: (params = {}, utmParams = {}) =>
          toUrl(
            PATHS.links.overview,
            {
              ...params,
              ...toUtm(utmParams)
            },
            ['timeframe', 'table-mode']
          )
      },
      overview_v2: {
        url: (filters = {}, link_id = '', utmParams = {}) =>
          toUrl(
            PATHS.links.overview_v2,
            {
              ...toUtm(utmParams),
              link_id,
              timeframe: filters.timeframe
                ? `${filters.timeframe.start}---${filters.timeframe.end}`
                : undefined
            },
            ['timeframe']
          )
      },
      amazonIssues: {
        url: (params = {}, utmParams = {}) =>
          toUrl(
            PATHS.links.amazonIssues,
            {
              ...params,
              ...toUtm(utmParams)
            },
            ['timeframe', 'table-mode', 'amazonIssues']
          )
      },
      scans: {
        history: {
          url: (utmParams = {}) =>
            toUrl(PATHS.links.scans.history, toUtm(utmParams))
        },
        details: {
          url: (scanId, utmParams = {}) =>
            toUrl(
              interpolatePath(PATHS.links.scans.details, {
                scanId
              }),

              toUtm(utmParams)
            )
        }
      },
      settings: {
        url: (utmParams = {}) => toUrl(PATHS.links.settings, toUtm(utmParams))
      },
      amazonSettings: {
        url: (utmParams = {}) =>
          toUrl(PATHS.links.amazonSettings, toUtm(utmParams))
      },
      details: {
        overview: {
          url: (
            productId,
            back: 'overview' | 'amazonIssues' = 'overview',
            utmParams = {}
          ) =>
            toUrl(
              interpolatePath(PATHS.links.details.overview, {
                productId
              }),
              { ...toUtm(utmParams), back },
              ['timeframe']
            )
        },
        pages: {
          url: (
            productId,

            back: 'overview' | 'amazonIssues' = 'overview',
            utmParams = {}
          ) =>
            toUrl(
              interpolatePath(PATHS.links.details.pages, {
                productId
              }),

              { ...toUtm(utmParams), back },
              ['timeframe']
            )
        },
        audience: {
          url: (
            productId,
            back: 'overview' | 'amazonIssues' = 'overview',
            utmParams = {}
          ) =>
            toUrl(
              interpolatePath(PATHS.links.details.audience, {
                productId
              }),

              { ...toUtm(utmParams), back },
              ['timeframe']
            )
        }
      }
    },
    campaigns: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.campaigns.overview, toUtm(utmParams))
      },
      complete: {
        url: (utmParams = {}) =>
          toUrl(PATHS.campaigns.complete, toUtm(utmParams))
      },
      details: {
        url: (campaignId, back = 'overview', utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.campaigns.details, {
              campaignId
            }),
            { ...toUtm(utmParams), back }
          )
      },
      report: {
        url: (campaignId, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.campaigns.report, {
              campaignId
            }),
            toUtm(utmParams)
          )
      },
      create: {
        url: (utmParams = {}) => toUrl(PATHS.campaigns.create, toUtm(utmParams))
      },
      performance: {
        url: (utmParams = {}) =>
          toUrl(PATHS.campaigns.performance, toUtm(utmParams))
      },
      settings: {
        url: (utmParams = {}) =>
          toUrl(PATHS.campaigns.settings, toUtm(utmParams))
      }
    },
    tools: {
      linkGenerator: {
        url: (utmParams = {}) =>
          toUrl(PATHS.tools.linkGenerator, toUtm(utmParams), ['timeframe'])
      }
    },
    docs: {
      knowledgeBase: {
        url: (slug = '', utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.docs.knowledgeBase, {
              slug
            }),
            toUtm(utmParams)
          )
      },
      api: {
        url: (slug = '', utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.docs.api, {
              slug
            }),
            toUtm(utmParams)
          )
      }
    },
    audit: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.audit.overview, toUtm(utmParams), ['timeframe'])
      },
      details: {
        url: (siteUrl, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.audit.details, {
              siteUrl: encodeURIComponent(siteUrl)
            }),
            toUtm(utmParams),
            ['timeframe']
          )
      }
    },
    settings: {
      general: {
        url: (utmParams = {}) => toUrl(PATHS.settings.general, toUtm(utmParams))
      },
      tracking: {
        url: (utmParams = {}) =>
          toUrl(PATHS.settings.tracking, toUtm(utmParams))
      },
      content: {
        url: (utmParams = {}) => toUrl(PATHS.settings.content, toUtm(utmParams))
      },
      notifications: {
        url: (utmParams = {}) =>
          toUrl(PATHS.settings.notifications, toUtm(utmParams))
      },
      profile: {
        url: (utmParams = {}) => toUrl(PATHS.settings.profile, toUtm(utmParams))
      },
      billing: {
        url: (utmParams = {}) => toUrl(PATHS.settings.billing, toUtm(utmParams))
      },
      api: {
        url: (utmParams = {}) => toUrl(PATHS.settings.api, toUtm(utmParams))
      },
      exports: {
        url: (utmParams = {}) => toUrl(PATHS.settings.exports, toUtm(utmParams))
      },
      browserExtension: {
        url: (params = { install: false }, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.settings.browserExtension, {
              install: params.install ? 'true' : 'false'
            }),
            toUtm(utmParams)
          )
      },
      affiliateProgram: {
        url: (utmParams = {}) =>
          toUrl(PATHS.settings.affiliateProgram, toUtm(utmParams))
      },
      users: {
        url: (utmParams = {}) => toUrl(PATHS.settings.users, toUtm(utmParams))
      }
    },

    base: {
      url: (opts = {}, utmParams = {}) => {
        const params: Query = {};
        if (opts.checkout) {
          params.checkout = 'true';
        }
        return toUrl('/', { ...params, ...toUtm(utmParams) });
      }
    },
    partners: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.partners.overview, toUtm(utmParams), ['timeframe'])
      },
      details: {
        url: (partnerKey: string, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.partners.details, { partnerKey }),
            toUtm(utmParams),
            ['timeframe']
          )
      }
    },
    performance: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performance.overview, toUtm(utmParams), ['timeframe'])
      },
      settings: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performance.settings, toUtm(utmParams))
      },
      reports: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performance.reports, toUtm(utmParams))
      },
      integrations: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performance.integrations, toUtm(utmParams))
      },
      labelRules: {
        url: (utmParams = {}) =>
          toUrl(PATHS.performance.labelRules, toUtm(utmParams))
      }
    },
    analytics: {
      content: {
        url: (utmParams = {}) =>
          toUrl(PATHS.analytics.content, toUtm(utmParams), ['timeframe'])
      },
      traffic: {
        url: (utmParams = {}) =>
          toUrl(PATHS.analytics.traffic, toUtm(utmParams), ['timeframe'])
      },
      audience: {
        url: (utmParams = {}) =>
          toUrl(PATHS.analytics.audience, toUtm(utmParams), ['timeframe'])
      }
    },
    products: {
      overview: {
        url: (params = {}, utmParams = {}) =>
          toUrl(
            PATHS.products.overview,
            {
              ...params,
              ...toUtm(utmParams)
            },
            ['timeframe']
          )
      },
      details: {
        url: (productId: string, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.products.details, { productId }),
            toUtm(utmParams),
            ['timeframe']
          )
      }
    },
    productScans: {
      overview: {
        url: (utmParams = {}) =>
          toUrl(PATHS.productScans.overview, toUtm(utmParams))
      },
      details: {
        url: (scanId: string, utmParams = {}) =>
          toUrl(
            interpolatePath(PATHS.productScans.details, { scanId }),
            toUtm(utmParams)
          )
      }
    },
    pages: {
      overview: {
        url: (search = '', utmParams = {}) =>
          toUrl(
            PATHS.pages.overview,
            {
              search,
              ...toUtm(utmParams)
            },
            ['timeframe']
          )
      },
      details: {
        url: (url: string, queryParams = {}, utmParams = {}) =>
          toUrl(
            PATHS.pages.details,
            { ...queryParams, ...toUtm(utmParams), url },
            ['timeframe']
          )
      },
      audience: {
        url: (url: string, queryParams = {}, utmParams = {}) =>
          toUrl(
            PATHS.pages.audience,
            { ...queryParams, ...toUtm(utmParams), url },
            ['timeframe']
          )
      },
      heatmap: {
        url: (url: string, queryParams = {}, utmParams = {}) =>
          toUrl(
            PATHS.pages.heatmap,
            { ...queryParams, ...toUtm(utmParams), url },
            ['timeframe']
          )
      },
      revisions: {
        url: (url: string, utmParams = {}) =>
          toUrl(PATHS.pages.revisions, { url, ...toUtm(utmParams) }, [])
      }
    },
    schedules: {
      home: {
        url: (utmParams = {}) => toUrl(PATHS.schedules.home, toUtm(utmParams))
      }
    }
  };
};

import moment from 'moment-timezone';
import {
  getTrackingId,
  SaleStatus
} from '../../../../../domainTypes/performance';
import * as Papa from 'papaparse';
import { fromMoment, now } from '../../../../../services/time';
import { toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';
import { CurrencyCode } from '../../../../../domainTypes/currency';

const DATE_FORMAT = 'YYYY-MM-DD';

const toDate = (dateStr: string) => {
  return moment.tz(dateStr, DATE_FORMAT, 'UTC');
};

const toStatus = (status: 'yes' | 'no'): SaleStatus => {
  if (status === 'no') {
    return 'Rejected';
  }
  if (status === 'yes') {
    return 'Final';
  }
  return 'Pending';
};

type TapfiliateRow = {
  type: 'commission';
  amount: string;
  approved: 'yes' | 'no';
  commission_amount: string;
  commission_kind: 'regular';
  commission_type: 'standard';
  conversion_id: string;
  currency: CurrencyCode;
  date: string; // YYYY-MM-DD
  program: string;
  subid1?: string;
  subid2?: string;
  subid3?: string;
  subid4?: string;
  subid5?: string;
};

export const TAPFILIATE: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'tapfiliate',
  parser: {
    name: 'Conversions and commissions',
    csvHeaders:
      'type,conversion_id,date,program,currency,amount,commission_amount,commission_type,commission_kind,approved',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (rows, { partnerKey, reportId, integrationId }) => {
      const csv = Papa.unparse(rows);
      const rowsWithHeaders = Papa.parse<TapfiliateRow>(csv, { header: true })
        .data;

      return rowsWithHeaders
        .filter((r) => r.type === 'commission')
        .map((row) => {
          const currency = row.currency;

          const price = toCents(row.amount);
          const commission = toCents(row.commission_amount);
          const saleDate = toDate(row.date);
          const status = toStatus(row.approved);
          const commissionPercent = toPercent(commission, price);
          const trackingId = getTrackingId(row.subid5 || '');
          const trackingLabel = row.subid1 || '';

          return {
            saleId: row.conversion_id,
            orderId: row.conversion_id,
            trackingId,
            trackingLabel,
            reportId,
            integrationId,
            saleDate: fromMoment(saleDate),
            completionDate: null,
            origin: null,
            status,
            partnerKey,
            partnerProductName: '',
            partnerProductId: '',
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: now(),
            coupon: '',
            saleType: 'unknown',
            amount: {
              currency,
              price,
              revenue: null,
              commission
            },
            commissionPercent,
            advertiserId: '',
            advertiserName: row.program
          };
        });
    }
  }
};

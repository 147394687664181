import {
  Button,
  Card,
  FormLabel,
  IconButton,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import { compact, flatten, uniq } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { AlignLeft, DownloadCloud, Grid } from 'react-feather';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../components/analytics_v2/Filters/Drawer';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { ChartCard } from '../../../../components/Charts/ChartCard';
import {
  EarningsBarChartCardMetricV2,
  EarningsChartCardWithoutDataV2
} from '../../../../components/Charts/EarningsChartCardV2';
import { ChartMode } from '../../../../components/Charts/EarningsChartCardV2/ChartModeSelector';
import { GraphMode } from '../../../../components/Charts/EarningsChartCardV2/GraphModeSelector';
import { CustomPagination } from '../../../../components/CustomPagination';
import {
  ExportQueryDialog,
  useExportQuery
} from '../../../../components/ExportQuery';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { TimeframeDurationChip } from '../../../../components/TimeframeDurationChip';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import {
  AnalyticsColumnTransformer,
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsIntervalUnit,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  AnalyticsSearch,
  clickhouseIntervalReturnToMomentFormat,
  ISOTimeRange,
  TransactionsQuery
} from '../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { css, styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useErrorLoggerWithLabel } from '../../../../hooks/useErrorLogger';
import { useWhatChanged } from '../../../../hooks/useWhatChanged';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { Section } from '../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  exportTransactions,
  useTransactionsQueryV2
} from '../../../../services/analyticsV2/transactions';
// import { allTime } from '../../../../services/analytics';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import { debuggedShallowEqual } from '../../../../services/debug';
import { useFeatureEnabled } from '../../../../services/features';
import { useHasPayouts } from '../../../../services/payouts';
import { getProductsByIdPg } from '../../../../services/products';
import { useTimeframeToIsoRange } from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { EmptyState } from '../../components/EmptyState';
import { ImportDialog } from '../../components/ImportDialog';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { SalesGroupHeaderV2 } from '../../components/SalesList';
import {
  ColumnName,
  COLUMNS,
  DEFAULT_VISIBLE_COLUMNS,
  SalesListV2
} from '../../components/SalesList/index2';
import { TotalsCardV2, TotalsV2 } from '../../components/TotalsCardV2';
import { TransactionExportDialog } from '../../components/TransactionExportDialog';
import { useHasAnyReports } from '../../services/report';
import {
  Grouper,
  GROUPERS,
  GroupSelectorV2,
  Sorter,
  SORTERS,
  SortSelectorV2
} from './groupersAndSorters';

const ExportOptionButton = styled(Button)`
  width: 250px;

  .MuiButton-label {
    justify-content: flex-start !important;
    gap: ${(p) => p.theme.spacing(1)}px !important;
  }
`;

const GridSection = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 2fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const queryParamToGroup = (p: string | undefined | null) => {
  if (p === 'none') {
    return null;
  }
  return p && GROUPERS[p] ? GROUPERS[p] : GROUPERS.date;
};

const isSameColumns = (a: ColumnName[], b: ColumnName[]) => {
  if (a.length !== b.length) {
    return false;
  }
  const toString = (x: ColumnName[]) => [...x].sort().join(',');
  return toString(a) === toString(b);
};

const TimeframePickerStandalone = () => {
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  return (
    <TimeframePickerDense
      value={timeframe}
      onChange={setTimeframe}
      options={options}
    />
  );
};

const NoMatchForCriteria = () => (
  <Centered height={400}>
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h4" component="p" paragraph>
        We didn't find any matches for your criteria!
      </Typography>
      <Typography variant="body1" component="p">
        Try increasing the date range for a chance at finding sales that meet
        this criteria.
      </Typography>
    </div>
  </Centered>
);

const NoMatchForSearch = () => (
  <Centered height={400}>
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h4" component="p" paragraph>
        We didn't find any matches for your search!
      </Typography>
      <Typography variant="body1" component="p">
        Search will look at the product name or tracking label for every sale
        you've made.
      </Typography>
      <Typography variant="body1" component="p">
        Double-check for typos, try increasing the date range, or customize the
        preset to apply custom filters.
      </Typography>
    </div>
  </Centered>
);

const GROUP_MODE_SECTION_LIMIT = 50;

const GroupModeSectionSalesV2 = ({
  grouper,
  group,
  sorter,
  columns,
  spaceId,
  currency,
  tz,
  range,
  filters,
  columnTransformers,
  search
}: {
  grouper: Grouper;
  group: { [groupKey: string]: string };
  sorter: Sorter;
  columns: Set<ColumnName>;

  spaceId: string;
  currency: CurrencyCode;
  tz: string;
  range: ISOTimeRange;

  filters: AnalyticsFilter[];
  columnTransformers: AnalyticsColumnTransformer[];
  search: AnalyticsSearch[];
}) => {
  // group contains information about this particular group,
  // like a specific page_url, or a dev'ice
  // If we're grouping by date then only interval is defined in there
  // We don't know what the interval is here - but at the moment
  // the only granularity we have is daily, so we can safely assume
  // that the date we get there is just a date.
  // Such an interval overwrites the range we are asking on this screen
  const visibleColumns = useMemo(
    () => COLUMNS.filter((c) => columns.has(c.key)),
    [columns]
  );
  const [page, setPage] = useState(1);
  const q = useMemo<TransactionsQuery>(() => {
    const _q: TransactionsQuery = {
      select: [...flatten(visibleColumns.map((c) => c.requiredFields))], // this will be based on the columns that are asked for after we change how they are defined
      filters,
      columnTransformers,
      search,
      range,
      orderBy: [
        // TODO check what to sort by. Either commission or sale_date
        // Bit tedious to check out the values of sorter (it's key) and determine based on that
        sorter.key === 'date'
          ? {
              field: 'sale_date',
              direction: 'DESC'
            }
          : {
              field: 'commission',
              direction: 'DESC'
            }
      ],
      paginate: {
        page,
        limit: GROUP_MODE_SECTION_LIMIT
      }
    };
    Object.entries(group).forEach(([k, v]) => {
      if (k === 'interval') {
        if (v === 'ALL') {
          return;
        }
        const grouperInterval = grouper.interval?.(tz);
        if (!grouperInterval) {
          console.warn('Expected grouper interval to be defined', grouper);
        }
        const unit = grouperInterval?.unit || 'day';
        const momentFormat = clickhouseIntervalReturnToMomentFormat(unit);
        const startM = moment.tz(v, momentFormat, tz);
        _q.range = {
          start: startM.toISOString(),
          end: startM.clone().add(1, unit).toISOString()
        };
      } else {
        _q.filters = [
          ...(_q.filters || []),
          {
            field: k as AnalyticsField,
            condition: 'in',
            values: [v]
          }
        ];
      }
    });
    return _q;
  }, [
    search,
    filters,
    columnTransformers,
    group,
    tz,
    range,
    page,
    visibleColumns,
    sorter,
    grouper
  ]);

  const [data, loading, error] = useTransactionsQueryV2(spaceId, q);

  useErrorLoggerWithLabel('transaction group', error);

  if (!data) {
    return (
      <Card>
        <Loader height={200} />
      </Card>
    );
  }
  const showPagination =
    page > 1 || data.rows.length === GROUP_MODE_SECTION_LIMIT;
  const hasNextPage = data.rows.length === GROUP_MODE_SECTION_LIMIT;

  return (
    <FlexContainerVertical fullWidth>
      <SalesListV2
        ds={data.rows}
        visibleColumns={columns}
        currency={currency}
        tz={tz}
      />
      {showPagination && (
        <FlexContainer>
          <CustomPagination
            page={page}
            count={page + (hasNextPage ? 1 : 0)}
            siblingCount={0}
            onChange={(_, newPage) => {
              setPage(newPage);
            }}
          />
          {loading && <InlineLoader />}
        </FlexContainer>
      )}
    </FlexContainerVertical>
  );
};

const GroupModeSectionV2 = ({
  grouper,
  row,
  currency,
  ...props
}: {
  grouper: Grouper;
  sorter: Sorter;
  row: AnalyticsResponseRowWithComparison;
  columns: Set<ColumnName>;

  spaceId: string;
  currency: CurrencyCode;
  tz: string;
  range: ISOTimeRange;

  filters: AnalyticsFilter[];
  columnTransformers: AnalyticsColumnTransformer[];
  search: AnalyticsSearch[];
}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Section collapse={collapsed}>
      <SalesGroupHeaderV2
        data={row.data}
        currency={currency}
        expanded={!collapsed}
        onExpand={() => setCollapsed((x) => !x)}
      >
        <div>{grouper.toLabel(row)}</div>
      </SalesGroupHeaderV2>

      {!collapsed && (
        <GroupModeSectionSalesV2
          grouper={grouper}
          group={row.group}
          currency={currency}
          {...props}
        />
      )}
    </Section>
  );
};

const GROUPS_LIMIT = 500;

const GroupModeV2 = ({
  spaceId,
  currency,
  range,
  tz,
  visibleColumns,
  sorter,
  grouper,
  groups,
  search,
  filters,
  columnTransformers,
  groupsPage,
  setGroupsPage
}: {
  spaceId: string;
  currency: CurrencyCode;
  range: ISOTimeRange;
  tz: string;
  visibleColumns: Set<ColumnName>;
  sorter: Sorter;
  grouper: Grouper;
  groups: LoadingValue<AnalyticsResponseRowWithComparison[]>;

  filters: AnalyticsFilter[];
  columnTransformers: AnalyticsColumnTransformer[];
  search: AnalyticsSearch[];

  groupsPage: number;
  setGroupsPage: (nextGroupsPage: number) => void;
}) => {
  const [data, loading] = groups;
  return (
    <>
      {(!data || loading) && <Loader height={300} />}
      {data && !loading && (
        <>
          {!data.length && search.length === 0 && <NoMatchForCriteria />}
          {!data.length && search.length > 0 && <NoMatchForSearch />}
          {!!data.length && (
            <div>
              <div>
                {data.map((r) => {
                  return (
                    <GroupModeSectionV2
                      key={`${grouper.key}-${grouper.toKey(r)}`}
                      range={range}
                      grouper={grouper}
                      sorter={sorter}
                      row={r}
                      spaceId={spaceId}
                      currency={currency}
                      columns={visibleColumns}
                      tz={tz}
                      filters={filters}
                      columnTransformers={columnTransformers}
                      search={search}
                    />
                  );
                })}
              </div>
              {(groupsPage > 1 || data.length === GROUPS_LIMIT) && (
                <FlexContainer
                  justifyContent="flex-end"
                  marginTop={1}
                  fullWidth
                >
                  <CustomPagination
                    page={groupsPage}
                    count={groupsPage + (data.length === GROUPS_LIMIT ? 1 : 0)}
                    siblingCount={0}
                    onChange={(_, newPage) => {
                      setGroupsPage(newPage);
                    }}
                  />
                </FlexContainer>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const SalesBody = React.memo(
  ({
    currency,
    timeframeStart,
    timeframeEnd,
    timeframeTz
  }: {
    currency: CurrencyCode;
    timeframeStart: string;
    timeframeEnd: string;
    timeframeTz: string;
  }) => {
    const { space, tz } = useCurrentUser();
    const [searchTerm, setSearchTerm] = useStringQueryParam('q');
    const ref = React.useRef<HTMLButtonElement>(null);

    const filterDrawer = useFilterDrawerState(false, {
      localStorageKey: 'transactions-filter-drawer-state'
    });

    const showPayouts = useHasPayouts();
    const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
    const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');

    const filters = useFilterClauses();
    const {
      columnTransformers: channelIdColumnTranformers
    } = useChannelIdGrouper();
    const columnTransformers = useMemo(() => {
      return filters.some((f) => f.field === 'channel_id')
        ? channelIdColumnTranformers(space)
        : EMPTY_ARR;
    }, [filters, channelIdColumnTranformers, space]);

    const [groupBy, setGroupBy] = useQueryParam(
      'group',
      queryParamToGroup,
      (g) => (g === null ? 'none' : g.key)
    );
    const [exportMenuOpen, setExportMenuOpen] = useState(false);
    const {
      dialogOpen: exportDialogOpen,
      openDialog: setExportDialogOpen,
      closeDialog: onCloseExportDialog
    } = useDialogState();

    const {
      dialogOpen: exportQueryDialogOpen,
      openDialog: setExportQueryDialogOpen,
      closeDialog: onCloseExportQueryDialog
    } = useDialogState();

    const [sortBy, setSortBy] = useQueryParam(
      'sort',
      (p) => (p ? SORTERS[p] || null : null),
      (s) => (s ? s.key : undefined)
    );

    const [visibleColumns, setVisibleColumns] = useQueryParam(
      'columns',
      (p) =>
        new Set(p ? queryParamToList<ColumnName>(p) : DEFAULT_VISIBLE_COLUMNS),
      (cs) =>
        isSameColumns([...cs], DEFAULT_VISIBLE_COLUMNS)
          ? undefined
          : setToQueryParam(cs)
    );

    const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
      'graph_mode',
      'platform'
    );

    const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
      'chart_mode',
      'barChart'
    );

    const _groupBy = groupBy || GROUPERS.date;
    const _sorter = sortBy || _groupBy.defaultSorter;

    const timeframe = useMemo(
      () => ({ start: timeframeStart, end: timeframeEnd, tz: timeframeTz }),
      [timeframeStart, timeframeEnd, timeframeTz]
    );

    useWhatChanged(
      {
        filters,
        timeframe
      },
      'salesFilterArgs',
      { disable: !LOG }
    );

    const range = useTimeframeToIsoRange(timeframe);
    const search = useMemo<AnalyticsSearch[]>(() => {
      if (!searchTerm || searchTerm.length < 3) {
        return EMPTY_ARR;
      }
      return [
        {
          pattern: `%${searchTerm}%`,
          in: [
            'partner_product_name',
            'partner_product_id',
            'advertiser_name',
            'order_id',
            'tracking_label',
            'tracking_id',
            'page_url',
            'payout_id'
          ]
        }
      ];
    }, [searchTerm]);

    const [intervalUnit, setIntervalUnit] = useTypedStringQueryParam<
      AnalyticsIntervalUnit
    >('interval_unit', 'day');
    const interval = useMemo<AnalyticsInterval>(
      () => ({
        unit: intervalUnit,
        value: 1,
        tz
      }),
      [tz, intervalUnit]
    );
    const totalsQ = useMemo<AnalyticsQuery>(() => {
      return {
        range,
        filters,
        columnTransformers,
        search,
        select: [
          'commission_sum_net',
          'final_sum',
          'pending_sum',
          'refunded_sum',
          'commission_count_gross',
          'commission_count_net',
          'gmv_sum_net'
        ]
      };
    }, [range, filters, columnTransformers, search]);

    const [totals, loadingTotals] = useMappedLoadingValue(
      useAnalyticsQueryV2(space.id, totalsQ, {
        logLabel: 'sales totals',
        logMode: 'full'
      }),
      (res): TotalsV2 => {
        const row = res.rows[0];
        return {
          count_gross: row?.data.commission_count_gross?.curr || 0,
          count_net: row?.data.commission_count_net?.curr || 0,
          total: row?.data.commission_sum_net?.curr || 0,
          final: row?.data.final_sum?.curr || 0,
          pending: row?.data.pending_sum?.curr || 0,
          refunded: row?.data.refunded_sum?.curr || 0,
          gmv_sum_net: row?.data.gmv_sum_net?.curr || 0
        };
      }
    );

    const [metric, setMetric] = useTypedStringQueryParam<
      EarningsBarChartCardMetricV2
    >('metric', 'commission_sum_net');

    const scopes = useCurrentUserScopes();

    const [groupsPage, setGroupsPage] = useState(1);

    const groupsQ = useMemo<AnalyticsQuery>(() => {
      const _q: AnalyticsQuery = {
        range,
        columnTransformers,
        filters,
        search,
        select: uniq([
          'commission_sum_net',
          'commission_count_gross',
          'gmv_sum_net',
          'order_count_net',
          'quantity_net',
          ...(_sorter.additionalFieldsToSelect || [])
        ]),
        orderBy: _sorter.orderBy,
        paginate: {
          page: groupsPage,
          limit: GROUPS_LIMIT
        }
      };
      if (_groupBy.interval) {
        _q.interval = _groupBy.interval(tz);
      }
      if (_groupBy.groupBy) {
        _q.groupBy = _groupBy.groupBy;
      }
      return _q;
    }, [
      range,
      columnTransformers,
      filters,
      search,
      _groupBy,
      _sorter,
      tz,
      groupsPage
    ]);

    const groupsExportQ = useExportQuery(groupsQ);
    const groupsLv = useMappedLoadingValue(
      useAnalyticsQueryV2(space.id, groupsQ, {
        logLabel: 'groups',
        logMode: 'compact'
      }),
      (resp) => {
        if (_groupBy.key === 'linkClicked') {
          // Pre-loading the cache.
          // All these groups will request one product per row.
          // They will all hit a deduplicated cached entry.
          // This is more efficient as this way we can batch the request.
          const linkIds = compact(
            resp.rows.map((r) => _groupBy.toKey(r) || null)
          );

          if (linkIds.length) {
            getProductsByIdPg(space.id, linkIds);
          }
        }
        return resp.rows;
      }
    );

    return (
      <>
        <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
          <PageToolbarSection flex={10}>
            <Typography
              variant="h6"
              component="span"
              style={{
                marginRight: '9px',
                position: 'relative',
                fontWeight: 'bold',
                top: '-2px'
              }}
            >
              Transactions
            </Typography>
            <SearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search transactions"
              size="small"
              width={200}
              autoFocus
            />
            &nbsp;
            <GroupSelectorV2 value={_groupBy} onChange={setGroupBy} />
            <SortSelectorV2
              value={_sorter}
              onChange={setSortBy}
              defaultValue={_groupBy.defaultSorter}
              sorters={SORTERS}
            />
            <ColumnSelector
              value={visibleColumns}
              onChange={setVisibleColumns}
              columns={COLUMNS}
            />
            <FiltersIcon {...filterDrawer} />
          </PageToolbarSection>
          <PageToolbarSection flex={1} justifyContent="flex-end">
            <TimeframePickerStandalone />
            {scopes.has('reports.transactions.export') && (
              <>
                <Tooltip title="Export transactions" placement="top">
                  <IconButton
                    ref={ref}
                    onClick={() => {
                      setExportMenuOpen(!exportMenuOpen);
                    }}
                  >
                    <DownloadCloud size={18} />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={exportMenuOpen}
                  anchorEl={ref.current}
                  onClose={() => setExportMenuOpen(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  PaperProps={{
                    style: {
                      width: '260px',
                      display: 'grid',
                      alignItems: 'left',
                      padding: '8px'
                    }
                  }}
                >
                  <FormLabel
                    classes={{
                      root: css(() => ({
                        fontWeight: 'bold',
                        padding: '8px'
                      }))
                    }}
                    component="legend"
                  >
                    Export options
                  </FormLabel>
                  <ExportOptionButton onClick={setExportDialogOpen}>
                    <AlignLeft size={16} /> Export invidual transactions
                  </ExportOptionButton>
                  <ExportOptionButton
                    onClick={setExportQueryDialogOpen}
                    disabled
                  >
                    <Grid size={16} /> Export group totals (Coming soon)
                  </ExportOptionButton>
                </Popover>
              </>
            )}
          </PageToolbarSection>

          <PageToolbarOtherRow>
            <FiltersDrawer
              isOpen={filterDrawer.isOpen}
              orderBy={'commission_sum_net'}
              availableDimensions={compact([
                showCustomDimensions && 'click_data_01',
                showCustomDimensions && 'click_data_02',
                showTrafficSources && 'utm_medium',
                showTrafficSources && 'utm_source',
                showTrafficSources && 'utm_campaign',
                showTrafficSources && 'utm_content',
                showTrafficSources && 'utm_term',
                showTrafficSources && 'referrer',
                'channel',
                'platform',
                'advertiser',
                'transaction_status',
                'transaction_type',
                'device',
                showPayouts && 'payout_status',
                showPayouts && 'payout_id',
                'tag',
                'integration_id'
              ])}
              marginTop={2}
              renderContext={{ page: 'TRANSACTIONS' }}
            />
          </PageToolbarOtherRow>
        </PageToolbar>

        <GridSection>
          <ChartCard
            noMaximize
            centered
            heading={`Total earnings`}
            subheading={<TimeframeDurationChip tf={timeframe} />}
            size="small"
            padding="dense"
            topRight={
              totals && loadingTotals ? <InlineLoader color="inherit" /> : null
            }
          >
            <TotalsCardV2
              totals={totals}
              loading={loadingTotals}
              currency={currency}
            />
          </ChartCard>
          <EarningsChartCardWithoutDataV2
            space={space}
            range={range}
            interval={interval}
            intervalUnit={intervalUnit}
            setIntervalUnit={setIntervalUnit}
            filters={filters}
            search={search}
            columnTransformers={columnTransformers}
            currency={currency}
            metric={metric}
            setMetric={setMetric}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
          />
        </GridSection>

        <GroupModeV2
          spaceId={space.id}
          range={range}
          tz={tz}
          visibleColumns={visibleColumns}
          search={search}
          filters={filters}
          columnTransformers={columnTransformers}
          grouper={_groupBy}
          sorter={_sorter}
          groups={groupsLv}
          currency={currency}
          groupsPage={groupsPage}
          setGroupsPage={setGroupsPage}
        />

        <TransactionExportDialog
          isOpen={exportDialogOpen}
          onClose={onCloseExportDialog}
          onExport={() =>
            exportTransactions(space.id, {
              range,
              filters,
              search,
              orderBy: [{ field: 'sale_date', direction: 'ASC' }]
            })
          }
        />
        <ExportQueryDialog
          query={groupsExportQ}
          reportType="transactions"
          title="Export transaction groups"
          open={exportQueryDialogOpen}
          onClose={onCloseExportQueryDialog}
        />
      </>
    );
  },
  debuggedShallowEqual('SalesBody')
);

const LOG = false;

export const PagePerformanceNewTransactionsPgAndClickhouse = () => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);
  const currency = useSpaceCurrency();
  const { space } = useCurrentUser();
  const [hasAnyReports] = useHasAnyReports(space.id);

  const body = () => {
    // if (loadingHasAnyReports) {
    //   return <Loader height={SALES_LOADER_HEIGHT} />;
    // }

    if (hasAnyReports === false) {
      // as opposed to just void and waiting for it
      return <EmptyState onImport={openDialog} />;
    }

    return (
      <Section>
        <SalesBody
          currency={currency}
          timeframeStart={timeframe.start}
          timeframeEnd={timeframe.end}
          timeframeTz={timeframe.tz}
        />
      </Section>
    );
  };

  return (
    <>
      <PerformancePageBody noTopPadding>{body()}</PerformancePageBody>
      <ImportDialog
        open={dialogOpen}
        onClose={closeDialog}
        currency={currency}
      />
    </>
  );
};

/*

TODO

- Define new groupers
- Define new sorters
- Define default sorter for groups
- Make query aware of sorter
- Add Group label renderer
- Update inner retrieval fn
- Update presets

- Make sure that when default sorter is engaged, empty and unknowns are sorted at the end

- Maybe add some limits, so that we don't retrieve the whole world?

- Paginate groups too - results sets can get *very large*
*/

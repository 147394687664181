import React from 'react';
import { ICampaign } from '../../../../domainTypes/campaigns';
import { Centered } from '../../../../layout/Centered';
import { LoadingValue } from '../../../../services/db';
import { Loader } from '../../../../components/Loader';

import { CampaignContent, CampaignDetails, CampaignLinks } from './form-types';
import { CampaignContentForm } from './CampaignContentForm';
import { CampaignLinksForm } from './CampaignLinksForm';
import { CampaignDetailsForm } from './CampaignDetailsForm';
import { CampaignPreview } from './CampaignPreview';

interface CampaignFormStepsProps {
  campaign: LoadingValue<ICampaign>;
  step: number;
  back: () => void;
  onSuccess: () => void;
  saveCampaignDetails: (details: CampaignDetails, campaign: ICampaign) => void;
  saveCampaignContent: (content: CampaignContent, campaign: ICampaign) => void;
  saveCampaignLinks: (links: CampaignLinks, campaign: ICampaign) => void;
}

export const CampaignFormSteps: React.FC<CampaignFormStepsProps> = ({
  step,
  back,
  onSuccess,
  campaign,
  saveCampaignDetails,
  saveCampaignContent,
  saveCampaignLinks
}) => {
  const [campaignData, loading] = campaign;

  if (!campaignData || loading) {
    return (
      <Centered>
        <Loader size={24} />
      </Centered>
    );
  }

  switch (step) {
    case 0:
      return (
        <CampaignDetailsForm
          campaign={campaignData}
          proceed={saveCampaignDetails}
        />
      );
    case 1:
      return (
        <CampaignContentForm
          campaign={campaignData}
          proceed={saveCampaignContent}
          back={back}
        />
      );
    case 2:
      return (
        <CampaignLinksForm
          campaign={campaignData}
          proceed={saveCampaignLinks}
          back={back}
        />
      );
    case 3:
      return (
        <CampaignPreview
          campaign={campaignData}
          back={back}
          onSuccess={onSuccess}
        />
      );
    default:
      return <Centered>Need to implement the next step</Centered>;
  }
};

import React, { useCallback, useMemo } from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { Helmet } from 'react-helmet';
import { useMixpanel, useTrackMixpanelView } from '../../../services/mixpanel';
import shortid from 'shortid';
import { useCampaignWithDefault } from '../service';
import { CampaignWizard } from '../components/form/CampaignWizard';
import { useRoutes } from '../../../routes';
import { useSnackbar } from 'notistack';

export const CampaignCreator: React.FC = () => {
  useTrackMixpanelView('view_campaigns_creator');
  const id = useMemo(() => shortid(), []);
  const campaign = useCampaignWithDefault(id);
  const [campaignData] = campaign;
  const { track } = useMixpanel();
  const { ROUTES, goTo } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = useCallback(() => {
    // NOTE: It's really 'campaign create complete' event
    track('create_campaign');
    if (campaignData) {
      enqueueSnackbar(`Campaign ${campaignData.name} created`, {
        variant: 'success'
      });
    }
    goTo(ROUTES.campaigns.overview.url());
  }, [ROUTES.campaigns.overview, campaignData, enqueueSnackbar, goTo, track]);

  return (
    <>
      <Helmet>
        <title>Create campaign | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <CampaignWizard campaign={campaign} onSuccess={onSuccess} />
      </CampaignsPageBody>
    </>
  );
};

import { CampaignsPageBody } from '../components/CampaignsPageBody';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useMixpanel, useTrackMixpanelView } from '../../../services/mixpanel';
import { useCampaignWithDefault } from '../service';
import { CampaignWizard } from '../components/form/CampaignWizard';
import { enqueueSnackbar } from 'notistack';
import { useRoutes, useStringQueryParam } from '../../../routes';

export const CampaignDetails: React.FC<{
  campaignId: string;
}> = ({ campaignId }) => {
  useTrackMixpanelView('view_campaigns_detail', { id: campaignId });
  const { ROUTES, goTo } = useRoutes();
  const { track } = useMixpanel();
  const campaign = useCampaignWithDefault(campaignId); // Use something like `useCampaignData`
  const [campaignData] = campaign;
  // TODO: use url to navigate after Preview step!
  const [back] = useStringQueryParam('back', 'overview');
  const url = useMemo(() => {
    return back === 'overview'
      ? ROUTES.campaigns.overview.url()
      : ROUTES.campaigns.report.url(campaignId);
  }, [ROUTES.campaigns.overview, ROUTES.campaigns.report, back, campaignId]);
  const onSuccess = useCallback(() => {
    // NOTE: It's really 'campaign edit complete' event
    track('edit_campaign');
    if (campaignData) {
      enqueueSnackbar(`Campaign ${campaignData.name} edited`, {
        variant: 'success'
      });
    }
    goTo(url);
  }, [campaignData, goTo, track, url]);

  return (
    <>
      <Helmet>
        <title>Campaign Details | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <CampaignWizard campaign={campaign} onSuccess={onSuccess} />
      </CampaignsPageBody>
    </>
  );
};

import React from 'react';
import { RunningCampaign } from '../service';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { formatNumber } from '../../../components/Number';
import { Target } from 'react-feather';
import { COLORS } from '../../../domainTypes/colors';
import { css } from '../../../emotion';
import { CampaignMetricWithGoal } from '../service/totals';
import { Metric, metricName } from '../../../services/analyticsV2/metrics';

export const TargetStatus: React.FC<{
  metric: Required<CampaignMetricWithGoal>;
  campaign: RunningCampaign;
  field: Metric;
}> = ({ metric, campaign, field }) => {
  const goalRatio = metric.goal.percentage;
  const durationRatio =
    moment().diff(campaign.timeframe.start, 's') /
    moment(campaign.timeframe.end).diff(campaign.timeframe.start, 's');
  const isOnTrack = goalRatio >= durationRatio;
  return (
    <Tooltip
      placement="top"
      title={`${
        isOnTrack ? 'Campaign is on track.' : 'Campaign is running behind.'
      } You're ${formatNumber({
        n: durationRatio,
        format: 'percent'
      })} through the campaign timeline and have reached ${formatNumber({
        n: goalRatio,
        format: 'percent'
      })} of your ${metricName(field)} goal.`}
    >
      <Target
        size={16}
        color={isOnTrack ? COLORS.blue.blue5 : COLORS.gold.gold6}
        className={css((t) => ({
          marginLeft: t.spacing(0.5),
          top: 3,
          position: 'relative'
        }))}
      />
    </Tooltip>
  );
};

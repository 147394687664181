import React from 'react';
import { CompletedCampaign } from '../../service';
import { CampaignReportToolbar } from './CampaignReportToolbar';
import { css } from '../../../../emotion';
import { CampaignTotalCards } from '../CampaignTotalCards';
import { CampaignTimeseries } from '../timeseries/CampaignTimeseries';
import { CampaignTables } from '../CampaignTables';
import { useCampaignTotals } from '../../service/totals';
import { CompletedCampaignSummary } from '../CompletedCampaignSummary';
import { CampaignSummaryLayout } from '../CampaignSummaryLayout';
import { useMetricSelectorState } from './MetricSelector';

export const CompletedCampaignReport: React.FC<{
  campaign: CompletedCampaign;
}> = ({ campaign }) => {
  const totals = useCampaignTotals(campaign);
  const [metric, setMetric] = useMetricSelectorState(campaign);

  return (
    <>
      <CampaignReportToolbar
        campaign={campaign}
        metric={metric}
        setMetric={setMetric}
      />
      <div
        className={css((t) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: t.spacing(4)
        }))}
      >
        <CampaignTotalCards
          campaign={campaign}
          totals={totals}
          cards={['c', 'earnings', 'flatSpend', 'gmv', 'orders', 'aov']}
        />
        <CampaignTimeseries campaign={campaign} metric={metric}>
          <CampaignSummaryLayout totals={totals}>
            {(totalValues) => {
              return (
                <CompletedCampaignSummary
                  campaign={campaign}
                  totalValues={totalValues}
                />
              );
            }}
          </CampaignSummaryLayout>
        </CampaignTimeseries>
        <CampaignTables campaign={campaign} metric={metric} />
      </div>
    </>
  );
};

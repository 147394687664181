import React from 'react';
import { Card } from '@material-ui/core';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { FlexContainer } from '../../../layout/Flex';
import { metricTitle } from '../../../services/analyticsV2/metrics';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  CampaignTotals,
  CampaignTotal,
  CampaignTotalsObject,
  hasGoal
} from '../service/totals';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';
import {
  CompletedCampaign,
  isCampaignRunning,
  RunningCampaign
} from '../service';
import { TargetStatus } from './TargetStatus';
import { formatNumber } from '../../../components/Number';

type CardName =
  | 'earnings'
  | 'flatSpend'
  | 'gmv'
  | 'orders'
  | 'aov'
  | 'c'
  | 'p'
  | 'v';

const BaseCard: React.FC<{
  totals: CampaignTotals;
  title: string;
  children: (values: CampaignTotalsObject) => React.ReactNode;
}> = ({ totals, title, children }) => {
  const [values, loading] = totals;
  return (
    <Card
      className={css(() => ({
        flex: 1
      }))}
    >
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      {loading || !values ? (
        <Skeleton
          className={css(() => ({
            width: '60%',
            fontSize: '1.5rem'
          }))}
        />
      ) : (
        <FlexContainer alignItems="baseline" spacing={0.5}>
          {children(values)}
        </FlexContainer>
      )}
    </Card>
  );
};

const CurrencyCard: React.FC<{
  title: string;
  totals: CampaignTotals;
  field: CampaignTotal;
}> = ({ title, totals, field }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">
          {formatChartCurrency(values[field], currency, { excludeCents: true })}
        </Typography>
      )}
    </BaseCard>
  );
};

const NumberCard: React.FC<{
  title: string;
  totals: CampaignTotals;
  field: CampaignTotal;
}> = ({ title, totals, field }) => {
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">{formatChartNumber(values[field])}</Typography>
      )}
    </BaseCard>
  );
};

// TODO: TrafficGoalCard and GMVGoalCard are very similar, consider refactoring if we go with those components
const TrafficGoalCard: React.FC<{
  campaign: RunningCampaign | CompletedCampaign;
  totals: CampaignTotals;
  field: 'c' | 'p';
}> = ({ totals, campaign, field }) => {
  return (
    <BaseCard totals={totals} title={metricTitle(field)}>
      {(values) => {
        const metric = values[field];
        const value = (
          <Typography variant="h5">
            {formatChartNumber(metric.value)}
          </Typography>
        );
        if (!hasGoal(metric)) {
          return value;
        }
        return (
          <>
            {value}
            <Typography variant="body1" color="textSecondary">
              (
              {formatNumber({
                n: metric.goal.percentage,
                format: 'percent',
                digits: 1
              })}
              )
            </Typography>
            {isCampaignRunning(campaign) ? (
              <TargetStatus metric={metric} campaign={campaign} field={field} />
            ) : null}
          </>
        );
      }}
    </BaseCard>
  );
};

const GmvGoalCard: React.FC<{
  campaign: RunningCampaign | CompletedCampaign;
  totals: CampaignTotals;
}> = ({ totals, campaign }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={metricTitle('gmv_sum_net')}>
      {(values) => {
        const metric = values.gmv;
        const value = (
          <Typography variant="h5">
            {formatChartCurrency(metric.value, currency, {
              excludeCents: true
            })}
          </Typography>
        );
        if (!hasGoal(metric)) {
          return value;
        }
        return (
          <>
            {value}
            <Typography variant="body1" color="textSecondary">
              (
              {formatNumber({
                n: metric.goal.percentage,
                format: 'percent',
                digits: 1
              })}
              )
            </Typography>
            {isCampaignRunning(campaign) ? (
              <TargetStatus
                metric={metric}
                campaign={campaign}
                field={'gmv_sum_net'}
              />
            ) : null}
          </>
        );
      }}
    </BaseCard>
  );
};

const MetricCard = ({
  name,
  totals,
  campaign
}: {
  campaign: CompletedCampaign | RunningCampaign;
  totals: CampaignTotals;
  name: CardName;
}) => {
  switch (name) {
    case 'c':
      return <TrafficGoalCard campaign={campaign} totals={totals} field="c" />;
    case 'p':
      return <TrafficGoalCard campaign={campaign} totals={totals} field="p" />;
    case 'gmv':
      return <GmvGoalCard campaign={campaign} totals={totals} />;
    case 'v':
      return <NumberCard title={metricTitle('v')} totals={totals} field="v" />;
    case 'earnings':
      return (
        <CurrencyCard
          title={metricTitle('commission_sum_net')}
          totals={totals}
          field="commission"
        />
      );
    case 'flatSpend':
      return (
        <CurrencyCard title="Flat spend" totals={totals} field="flatSpend" />
      );
    case 'orders':
      return (
        <NumberCard
          title={metricTitle('order_count_net')}
          totals={totals}
          field="orderCount"
        />
      );
    case 'aov':
      return (
        <CurrencyCard
          title={metricTitle('aov_net')}
          totals={totals}
          field="aov"
        />
      );
  }
};

export const CampaignTotalCards = ({
  totals,
  cards,
  campaign
}: {
  totals: CampaignTotals;
  cards: CardName[];
  campaign: CompletedCampaign | RunningCampaign;
}) => {
  return (
    <FlexContainer spacing={2}>
      {cards.map((name) => (
        <MetricCard name={name} totals={totals} campaign={campaign} />
      ))}
    </FlexContainer>
  );
};

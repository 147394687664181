import { Divider, Paper } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import { ArrowRight, Edit, Info } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  Area,
  AreaChart,
  AxisDomain,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';
import { UserAvatarGroup } from '../../../components/UserAvatars';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import { COLORS } from '../../../domainTypes/colors';
import { css, styled } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';
import { useRoutes } from '../../../routes';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../services/currentUser';
import { useMappedLoadingValue } from '../../../services/db';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  calculateEarningsGoal,
  Campaign,
  campaignQueryBase,
  getFlatSpendAmount,
  isCampaignRunning,
  isRateIncreaseCampaignIncentive,
  RunningCampaign
} from '../service';
import { cumulativeSum } from '../service/aggregate';
import { TargetStatus } from './TargetStatus';
import { hasGoal, useCampaignTotals } from '../service/totals';
import { DateChip, ArticlesChip, GoalChip, PlacementsChip } from './chips';

const useCampaignTimeseries = (campaign: RunningCampaign) => {
  const { space } = useCurrentUser();
  const gmv = 'gmv_sum_net';
  const query = useMemo<AnalyticsQuery>(
    () => ({
      ...campaignQueryBase(campaign),
      select: [gmv],
      interval: {
        unit: 'day',
        value: 1,
        tz: space.config.tz
      }
    }),
    [campaign, space.config.tz]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => {
      const data = cumulativeSum(response.rows, 'gmv_sum_net');
      return {
        ...data,
        series: data.series.map((row) => ({
          timestamp: row.group.interval,
          value: row.data[gmv]?.curr
        }))
      };
    }
  );
};

const PaperDivider = styled(Divider)`
  background-color: ${(t) => t.theme.palette.grey['200']} !important;
`;

const Container = styled('div')`
  padding: ${(t) => t.theme.spacing(1.5)}px ${(t) => t.theme.spacing(1.5)}px;
`;

const Header: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  const { ROUTES } = useRoutes();
  const routeUrl = isCampaignRunning(campaign)
    ? ROUTES.campaigns.report.url(campaign.id)
    : ROUTES.campaigns.details.url(campaign.id, 'overview');

  return (
    <Container>
      <FlexContainer justifyContent="space-between" alignItems="flex-start">
        <div>
          <Link to={routeUrl}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {campaign.name}
            </Typography>
          </Link>
          <FlexContainer
            className={css((t) => ({
              gap: t.spacing(2)
            }))}
            wrap="wrap"
          >
            {campaign.advertisers.map((advertiser) => (
              <Typography
                variant="body2"
                key={advertiser.id}
                color="textSecondary"
              >
                {advertiser.name}
              </Typography>
            ))}
          </FlexContainer>
        </div>
        <FlexContainer spacing={2}>
          <Tooltip title="Edit campaign" placement="top">
            <Link to={ROUTES.campaigns.details.url(campaign.id, 'overview')}>
              <Edit size={16} />
            </Link>
          </Tooltip>
          {isCampaignRunning(campaign) && (
            <Tooltip title="Show campaign report" placement="top">
              <Link to={ROUTES.campaigns.report.url(campaign.id)}>
                <ArrowRight size={16} />
              </Link>
            </Tooltip>
          )}
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};

const Sparkline: React.FC<{
  goal: number;
  data: {
    series: { timestamp: string; value?: number }[];
    sum: number;
    lastInterval?: string | undefined;
    intervalAfterNow?: string | undefined;
  };
}> = ({ data, goal }) => {
  const domain = useMemo<Readonly<[AxisDomain, AxisDomain]>>(() => {
    return [0, (max: number) => Math.ceil(Math.max(max, goal) / 10) * 10];
  }, [goal]);

  return (
    <ResponsiveContainer width="99%" height={50}>
      <AreaChart data={data.series}>
        <defs>
          <linearGradient id={`sparkline_gradient`} x1={0} x2={0} y1={0} y2={1}>
            <stop offset="5%" stopColor={COLORS.blue.blue3} stopOpacity={0.8} />
            <stop offset="95%" stopColor={COLORS.blue.blue3} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="timestamp" hide />
        <YAxis dataKey="value" domain={domain} hide />
        <Area
          dataKey="value"
          type="monotone"
          strokeOpacity={1}
          strokeWidth={1.5}
          stroke={COLORS.blue.blue5}
          fill="url(#sparkline_gradient)"
          fillOpacity={1}
          activeDot={false}
          isAnimationActive={false}
        />
        <ReferenceLine
          stroke={COLORS.blue.blue5}
          strokeWidth={2}
          strokeDasharray="5 5"
          segment={[
            {
              x: data.intervalAfterNow!,
              y: data.sum
            },
            {
              x: data.lastInterval!,
              y: goal
            }
          ]}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CampaignTrend: React.FC<{ campaign: RunningCampaign }> = ({
  campaign
}) => {
  const [data, loading] = useCampaignTimeseries(campaign);
  if (loading || !data) return null;
  return (
    <div
      className={css(() => ({
        flexBasis: 80,
        flexGrow: 1,
        maxWidth: 300
      }))}
    >
      <Sparkline data={data} goal={calculateEarningsGoal(campaign)} />
    </div>
  );
};

const CardBodyGrid = styled('div')`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: ${(t) => t.theme.spacing(0.5)}px;
  align-items: center;
  padding: ${(t) => t.theme.spacing(2)}px;
`;

const Body: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  const currency = useSpaceCurrency();
  const flatSpend = getFlatSpendAmount(campaign);
  return (
    <CardBodyGrid>
      <FlexContainer alignItems="center">
        <UserAvatarGroup
          spaceId={campaign.spaceId}
          userIds={campaign.managers}
          size={40}
          max={2}
        />
        <div>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {formatChartCurrency(flatSpend, currency, {
              excludeCents: true
            })}{' '}
            flat spend
          </Typography>
          {campaign.incentives
            .filter(isRateIncreaseCampaignIncentive)
            .map((i) => (
              <Typography variant="caption" color="textSecondary">
                Rate increase to {i.to}%{' '}
                <Tooltip
                  title={`Rate increase from ${i.from}% to ${i.to}%: ${i.description}`}
                  placement="top"
                >
                  <Info
                    size={12}
                    style={{ position: 'relative', top: '1px' }}
                  />
                </Tooltip>
              </Typography>
            ))}
        </div>
      </FlexContainer>
      {isCampaignRunning(campaign) && <CampaignTrend campaign={campaign} />}
    </CardBodyGrid>
  );
};

const CampaignMetricsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${(t) => t.theme.spacing(1)}px;
  margin-bottom: ${(t) => t.theme.spacing(2)}px;
`;

const CampaignMetrics: React.FC<{ campaign: RunningCampaign }> = ({
  campaign
}) => {
  const [data, loading] = useCampaignTotals(campaign);
  const currency = useSpaceCurrency();
  if (loading || !data) return null;
  return (
    <CampaignMetricsGrid>
      <Typography variant="caption" color="textSecondary">
        <strong>
          {formatChartCurrency(data.gmv.value, currency, {
            excludeCents: true
          })}
        </strong>{' '}
        GMV
        {hasGoal(data.gmv) && (
          <TargetStatus
            campaign={campaign}
            field="gmv_sum_net"
            metric={data.gmv}
          />
        )}
      </Typography>

      <Typography variant="caption" color="textSecondary">
        <strong>{formatChartNumber(data.c.value)}</strong> clicks
        {hasGoal(data.c) && (
          <TargetStatus campaign={campaign} field="c" metric={data.c} />
        )}
      </Typography>

      <Typography variant="caption" color="textSecondary">
        <strong>
          {formatChartCurrency(data.commission, currency, {
            excludeCents: true
          })}
        </strong>{' '}
        commissions
      </Typography>

      <Typography variant="caption" color="textSecondary">
        <strong>{formatChartNumber(data.p.value)}</strong> pageviews
        {hasGoal(data.p) && (
          <TargetStatus campaign={campaign} field="p" metric={data.p} />
        )}
      </Typography>
    </CampaignMetricsGrid>
  );
};

// NOTE: Is the idea that all of this should be refactored
// so it uses the ICampaign domain type?
const Footer: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  return (
    <Container>
      {isCampaignRunning(campaign) && <CampaignMetrics campaign={campaign} />}
      <FlexContainer
        wrap="wrap"
        marginTop={isCampaignRunning(campaign) ? 2 : 1}
      >
        <DateChip campaign={campaign} />
        <ArticlesChip campaign={campaign} />
        <PlacementsChip campaign={campaign} />
        {campaign.goals.map((goal) => (
          <GoalChip goal={goal} />
        ))}
      </FlexContainer>
    </Container>
  );
};

const CardPaper = styled(Paper)`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    border: 1px solid ${(p) => p.theme.palette.grey[100]};
  }
`;

export const CampaignCard = ({ campaign }: { campaign: Campaign }) => {
  return (
    <CardPaper>
      <Header campaign={campaign} />
      <PaperDivider />
      <Body campaign={campaign} />
      <PaperDivider />
      <Footer campaign={campaign} />
    </CardPaper>
  );
};
